/**
 * @param {string} base
 * @return {(path: string) => string}
 */
export const getUrlCreator = (base) => (path) => {
  const url = new URL(base);
  url.pathname = path;
  return url.toString();
};

export const getServerUrl = getUrlCreator(
  process.env.production || !process.env.REACT_APP_DEV_URL
    ? window.location
    : process.env.REACT_APP_DEV_URL
);
