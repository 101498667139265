import React from "react";
import styled from "styled-components";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Header = styled.header`
  display: block;
  font-size: var(--fontSize300);
  font-weight: bold;
  padding: var(--space800);
  border-bottom: var(--space200) solid var(--neutral400);
  width: 100%;
`;

const Main = styled.main`
  flex: 1 0 auto;
  width: 100%;
  max-width: var(--space1700);
  margin: var(--space800) auto;
`;

const ListItem = styled.li`
  padding: var(--space700);
  display: flex;
  justify-content: space-between;
  border: var(--space100) solid var(--neutral300);
  margin-bottom: var(--space600);
`;

const Link = styled.a`
  padding: var(--space500);
  font-size: var(--fontSize200);
  color: var(--primary700);
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

function SiteLink({ branch, env, children }) {
  return (
    <Link
      className="App-link"
      href={`https://${branch}-${env}.staging.${"#{staging-list-base-domain}"}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </Link>
  );
}

function Branch({ branch, completedAt }) {
  return (
    <ListItem>
      <Name>{branch}</Name>
      <nav>
        <SiteLink env="dev" branch={branch}>
          development
        </SiteLink>
      </nav>
    </ListItem>
  );
}

function App({ sites, jobs }) {
  const uniqueSites = Array.from(
    sites.reduce((acc, site) => {
      acc.add(site.split("-").slice(0, -1).join("-"));
      return acc;
    }, new Set())
  );

  return (
    <Layout>
      <Header>{"#{staging-list-app-name}"} Staging</Header>
      <Main>
        <ul>
          {uniqueSites.map((branch) => (
            <Branch key={branch} branch={branch} />
          ))}
        </ul>
      </Main>
    </Layout>
  );
}

export default App;
