import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { getServerUrl } from "./utils";

fetch(getServerUrl("/api/sites"))
  .then((response) => response.json())
  .then((sites) => sites.map(({ name }) => name))
  .then((sites) => {
    ReactDOM.render(
      <React.StrictMode>
        <App sites={sites} />
      </React.StrictMode>,
      document.getElementById("root")
    );
  });
